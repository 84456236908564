import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { userMenuDetails } from "../../redux/userMenu/userMenu.actions";
import axios from 'axios';
import { API_APP_B2B_FAILED_PAYMENTS } from '../../utils/consts/api';
import { toast } from 'react-toastify';
import AgTable from '../../components/Table/AgTable';
import { B2B_Failed_Payments_Fields } from '../../components/Table/tableData';
import SkeletonLoader from '../../components/Loader'
const FailedPayments = ({token,userMenuDetails}) => {

    const [failedPayments ,setFailedPayments] = useState([]);
    const [spinner,setSpinner]=useState(false);

    const getFailedPayments= async ()=>{
        setSpinner(true);
       try{
        const headers = {
            "Content-Type": "application/json",
            authorization: token,
          };
        let res= await axios.get(API_APP_B2B_FAILED_PAYMENTS , {headers} );
        
        if(res.data.responseCode === 0){
            setFailedPayments(res.data.response.failedPayments);
        }else{
            toast.error(res.data.errorMsg, {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
       }catch(err){
        toast.error("Internal server error.", {
            position: "top-right",
            autoClose: 4000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
       }
    }

    useEffect(()=>{getFailedPayments();},[]);

    return (
        <div style={{height:'95vh'}}>
        {
        spinner&&failedPayments.length==0?
        <SkeletonLoader/> :
      <AgTable
       rowData={failedPayments}
       colDefs={B2B_Failed_Payments_Fields}
       section={"B2B_Failed_Payments"}
      />
      }
        </div>
    );
}

FailedPayments.propTypes = {
    token: PropTypes.string,
  };
  
  const mapStateToProps = (state, props) => ({
    token: state.user.token,
  });
  
  export default connect(mapStateToProps, { userMenuDetails })(FailedPayments);
