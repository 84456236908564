/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import styles from './users.module.scss';
import axios from 'axios';
import { API_AGENT_LIST, API_APP_ENABLEDISABLEUSER, API_APP_GENRATEREPORT, API_DELETE_AGENT } from '../../utils/consts/api';
import dummy from '../../assets/images/default-Profile-Pic.jpg';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import DeleteuserComponent from './delete-user-modal';
import { userMenuDetails } from '../../redux/userMenu/userMenu.actions'
import * as moment from 'moment';
import AgTable from '../../components/Table/AgTable';
import { mobileUsers } from '../../components/Table/tableData';
import SkeletonLoader from '../../components/Loader';
import UniversalSearchTable from '../../components/Table/UniversalSearchTable';
import PinDialogue from '../../components/PinDialogue';

const ListComponent = ({ token, userMenuDetails }) => {
    const [showLoader, setShowLoader] = useState(false);
    const [spinner,setSpinner]=useState(false);
    const [userList, setuserList] = useState([]);
    const [usertListBackUp, setuserListBackUp] = useState([]);
    const pageLimit = useRef(5);
    const pageNo = useRef(0);
    const [pageNoMax] = useState(10000000);
    const [cacheNextUserList, setcacheNextUserList] = useState([]);
    const [cachePrevUserList, setcachePrevUserList] = useState([]);
    const [userStatus, setUserStatus] = useState('');
    const [SearchmodalShow,setSearchmodalShow]=useState(false);


    
    const getNextPrevUsersList = async (whichPage = 'next') => {
        whichPage = pageNo.current === 0 ? 'next' : whichPage;

        let nextPageNo = pageNo.current;
        if (whichPage === 'next') {
            nextPageNo = nextPageNo + 1;
        } else {
            nextPageNo = nextPageNo - 1;
        }
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_AGENT_LIST, { page: nextPageNo, size: pageLimit.current, searchString: query }, { headers });
            if (res.data.responseCode === 0) {
                if (whichPage === 'next') {
                    setcacheNextUserList(res.data.responseCode === 0 ? res.data.response.agentDetails : []);
                } else {
                    setcachePrevUserList(res.data.responseCode === 0 ? res.data.response.agentDetails : []);
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }


    const getAllUsersList = async () => {

        setSpinner(true);

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_AGENT_LIST, 
                // {page: pageNo.current,//  size: pageLimit.current ,
                //  size:2000,
                //  searchString: query },
                 {},
                 { headers });
            if (res.data.responseCode === 0) {
                setuserList(res.data.response.agentDetails);
                setuserListBackUp(res.data.response.agentDetails);
                setSpinner(false);
                if (res.data.response.agentDetails.length === pageLimit.current && cacheNextUserList.length === 0) {
                    // getNextPrevUsersList();
                }
            } else {

                setSpinner(false);

                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {

            setSpinner(false);

            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    const nextCacheSet = () => {
        if (cacheNextUserList.length > 0) {
            setcachePrevUserList(userList)
            //   this.cachePrevUserList = this.apiAllUsers;

            setuserList(cacheNextUserList);
            setuserListBackUp(cacheNextUserList);

            setcacheNextUserList([]);
            getNextPrevUsersList('next');
        } else {
            setcacheNextUserList([]);
            getAllUsersList();
        }
    }

    const prevCacheSet = () => {
        if (cachePrevUserList.length > 0) {
            setcacheNextUserList(userList);
            //   this.cacheNextUserList = this.apiAllUsers;
            setuserList(cachePrevUserList);
            setuserListBackUp(cachePrevUserList);
            setcachePrevUserList([]);
            getNextPrevUsersList('prev');
        } else {
            setcachePrevUserList([]);
            getAllUsersList();
        }
    }

    let i = 0;
    useEffect(() => {
        if (i === 0 && cacheNextUserList.length === 0) {
            getAllUsersList();
            i++;
        }
    }, [cacheNextUserList]);

    const resetCache = () => {
        pageNo.current = 0;
        setcacheNextUserList([]);
        setcachePrevUserList([]);
        i = 0
    }


    const nextPrev = (val) => {
        const page = pageNo.current + 1;

        if (val === 'next') {
            if (page < pageNoMax) {
                pageNo.current = pageNo.current + 1;
                nextCacheSet();
            }
        } else {
            if (page > 1) {
                pageNo.current = pageNo.current - 1;
                prevCacheSet();
            }
        }

    }

    const entriesPerPage = (e) => {
        pageLimit.current = parseInt(e.target.value, 10);
        resetCache();
    }
    const handleImageErr = (e) => {
        e.target.src = dummy;
    };

    const [modalShow, setModalShow] = useState(false);
    const seletedObj = useRef('');

    let navigate = useNavigate();
    const handleViewFunc = (seldata) => {
        userMenuDetails(seldata);
        navigate(`/app/users/${seldata.agentId}`);
    }
    const handleEditFunc = (seldata) => {
        userMenuDetails(seldata);
        navigate(`/app/usersEdit/${seldata.agentId}`);
    }

    const handleDeleteOpen = (seldata,pin) => {
        seletedObj.current = seldata;
        setModalShow(true);
        setUserStatus((prev)=>prev=seldata.status);
        handleDeleteaccept(seldata, pin)
    }



    // after handleDeleteaccept success update UI. 
    const toggleUserAction=(agentId,status)=>{
        let newData=userList.map((ele)=>{
            if(ele.agentId===agentId){
            
             ele.enabledStatus=status;
            }
            return ele;
        })

        setuserList(newData);
   }



    const handleDeleteaccept = async (seldata , pin ) => {
        
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const body={ accountId: seletedObj.current.agentId,
                         enabledStatus: seletedObj.current.enabledStatus === 1 ? 0 : 1,
                         pin: pin
                        }
            let agentId=seletedObj.current.agentId;
            const res = await axios.post(API_APP_ENABLEDISABLEUSER, body , { headers });
            if (res.data.responseCode === 0) {
                const status=res.data.response.currentStatus;
                toggleUserAction(agentId,status)
               
                toast.success(res.data.successMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
                seletedObj.current = '';
                setModalShow(false);
                setUserStatus('');
                // resetCache();
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 1000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const handleDeleteclose = () => {
        seletedObj.current = '';
        setUserStatus('');
        setModalShow(false);
    }
    const [query, setQuery] = useState('');

    const handleSubmit = async () => {
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_AGENT_LIST, { page: 0, size: 5, searchString: query }, { headers });
            if (res.data.responseCode === 0) {
                setuserList(res.data.response.agentDetails);
                setuserListBackUp(res.data.response.agentDetails);
                if (res.data.response.agentDetails.length === pageLimit.current && cacheNextUserList.length === 0) {
                    getNextPrevUsersList();
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }
    // const searchItems = (e) => {
    //     setQuery(e.target.value);
    //     if (e.target.value.length >= 3) {
    //         resetCache();
    //         handleSubmit();
    //     } else if (e.target.value.length === 0) {
    //         resetCache();
    //     }
    // };

    
    const handleSaveAsExcel = async (pin) => {
        setShowLoader(true);
        try {
            const headers = {
                "Content-Type": "application/json",
                authorization: token,
            };
            const body = { pin }
            const res = await axios.post(
                API_APP_GENRATEREPORT, body ,
                { headers }
            );
            if (res.data.responseCode === 0) {
                const link = document.createElement('a');
                link.href = res.data.downloadUrl;;
                document.body.appendChild(link);
                link.click();
                setShowLoader(false);
            } else {
                setShowLoader(false);
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            setShowLoader(false);
            toast.error("Internal server error.", {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    };

  useEffect(()=>{},[SearchmodalShow, userStatus]);
    return (
        <>
        
    <div className="row m-0">
      <div style={{height:"90vh"}}>
                {/* <div className="col-12">
                    <div className='d-flex'>
                        <h5 className="customTitleName">User List</h5>
                        <p className={`ms-3 ${styles.userSearchText}`}>{userList.length} users</p>
                        <div class="col-md-8">
                            <div class="d-flex form-inputs">
                                <input className={`form-control ${styles.searchBar}`} type="text"
                                    onChange={searchItems}
                                    placeholder="Search..."
                                    value={query}
                                />
                                <i class="fa fa-search" style={{ marginLeft: '-30px', opacity: 0.5, marginTop: '22px', cursor: 'pointer' }} onClick={handleSubmit}></i>
                            </div>
                        </div>
                    </div>
                    <div className={`card ${styles.customCard}`}>
                        <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <button className={`btn customButtonActive ${styles.customDisabledBtn}`} style={{ position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center' }} disabled={showLoader} onClick={handleSaveAsExcel}>Export Data
                                                {showLoader ? <div className={`buttonLoader`}></div> : null}
                                            </button>
                                        </div>
                                        <div className="w-100 customPginationDiv">
                                            <h6 className="customText">Items per page</h6>
                                            <select className="form-select customSelectWidth" name='pageLimit' onChange={entriesPerPage}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <button className="btn customButtons buttonMargin" disabled={pageNo.current === 0} onClick={() => nextPrev('prev')}><i className="fas fa-angle-left"></i></button>

                                            <button className="btn customButtons" disabled={pageLimit.current >= userList.length && cacheNextUserList.length === 0 || userList.length < 5} onClick={() => nextPrev('next')}>
                                                <i className="fas fa-angle-right"></i></button>
                                        </div>

                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-borderless custumTable">
                                            <thead className="tablehead">
                                                <tr>
                                                    <th className="headText">User</th>
                                                    <th className="headText">Email</th>
                                                    <th className="headText">Mobile Number</th>
                                                    <th className="headText">Company</th>
                                                    <th className="headText">User Type</th>
                                                    <th className="headText">Join Date</th>
                                                    <th className="headText">Status</th>
                                                    <th className="headText text-center">Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tableBody">

                                                {userList && userList.length > 0 && userList.map((ele) => (<tr key={ele.crmAgentId}>
                                                    <td>
                                                        <div className="d-flex w-100 align-items-center" key={ele.crmAgentId}>
                                                            <div>
                                                                <img src={ele.image} onError={handleImageErr} alt="" width="38" height="38"
                                                                    style={{ borderRadius: '50%' }} />
                                                            </div>
                                                            <div className="ms-2">
                                                                <h6 className="m-0 bodyText">{ele.fullName}</h6> */}
                                                                {/* <h6 className="m-0 locationText">Bangalore</h6> */}
                                                            {/* </div>
                                                        </div>
                                                    </td>
                                                    <td className={styles.emailText}>{ele.email ? ele.email : '-'}</td>
                                                    <td className="bodyText">{ele.mobileNumber ? ele.mobileNumber : '-'}</td>
                                                    <td className="bodyText">{ele.companyName ? ele.companyName : '-'}</td>
                                                    <td className="bodyText">{ele.userType ? ele.userType : '-'}</td>
                                                    <td className="bodyText">{moment(new Date(ele.createTs)).format('MMMM D, Y')}</td>

                                                    <td style={ele.verifiedFlag === "Verified" ? { color: "green" } : { color: "#FF4D4F" }}>
                                                        {ele.verifiedFlag}
                                                    </td>
                                                    <td className="bodyText text-center">
                                                        <OverlayTrigger
                                                            key={'top'}
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    Click to view
                                                                </Tooltip>
                                                            }
                                                        > */}
                                                            {/* <Link to={`/app/users/${ele.agentId}`} style={{ color: '#272b41' }}> */}
                                                            {/* <span className={styles.actionBtn}>
                                                                <i class="far fa-eye" onClick={() => handleViewFunc(ele)}></i>
                                                            </span> */}
                                                            {/* </Link> */}
                                                        {/* </OverlayTrigger>

                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement='bottom'
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    Click to edit
                                                                </Tooltip>
                                                            } */}
                                                        {/* > */}
                                                            {/* <Link to={`/app/usersEdit/${ele.agentId}`} style={{ color: '#272b41' }}> */}
                                                            {/* <span className={styles.actionBtn}>
                                                                <i class="fas fa-edit" onClick={() => handleEditFunc(ele)}></i> */}
                                                            {/* </span> */}
                                                            {/* </Link> */}
                                                        {/* </OverlayTrigger> */}

                                                        {/* <OverlayTrigger
                                                            key={'left'}
                                                            placement='left'
                                                            overlay={
                                                                <Tooltip id={`tooltip-left`}>
                                                                    Click to delete
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <i class="fa fa-trash-alt" style={{ opacity: '0.5', cursor: 'pointer' }} onClick={() => handleDeleteOpen(ele)}></i>
                                                        </OverlayTrigger> */}

                                                        {/* {ele.enabledStatus === 1 && <button type="button" class="btn btn-success" onClick={() => handleDeleteOpen(ele, 'Disable')}>Enabled</button>}
                                                        {ele.enabledStatus === 0 && <button type="button" class="btn btn-danger" onClick={() => handleDeleteOpen(ele, 'Enable')}>Disabled</button>}

                                                    </td>
                                                </tr>))}
                                                {userList && userList.length === 0 &&
                                                    <div className="text-center w-100">
                                                        <h4>No Data Found</h4>
                                                    </div>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}
            

            <div className='main-header'>

            <div  className='header'>
              <h1 className="customTitleName">User List</h1>
              {/* <button className="btn  customButtonActive " 
              onClick={handleSaveAsExcel} 
              disabled={showLoader}>
               {showLoader ? <div className={`buttonLoader`}>Downloading</div> : <div>
               Export Data 
               <svg xmlns="http://www.w3.org/2000/svg" width="28" height="18" fill="currentColor" class="bi bi-cloud-arrow-down-fill" viewBox="0 0 16 16">
                <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708"/>
               </svg>
            </div>}
              </button> */}
               <PinDialogue token={token}  downloadFile={handleSaveAsExcel}/>
         {/* ---------- */}
        </div>
         <div>
         <div className='searchContainer'>
         <div class="form-inputs">
        <UniversalSearchTable
            token={token}
            searchShow={SearchmodalShow}
            handleModal={setSearchmodalShow}
            section={"MobileUSers"}
            rowData={userList}
            colDefs={mobileUsers}
            handleImageErr={handleImageErr}
            handleViewFunc={handleViewFunc}
            handleEditFunc={handleEditFunc}
            handleDeleteOpen={handleDeleteOpen}
            toggleUserActionList={toggleUserAction}
        />
             </div>
         </div>
         </div>


            </div>



        {spinner&&userList.length===0?
            <SkeletonLoader/> :
               <AgTable
               section={"MobileUSers"}
               rowData={userList}
               colDefs={mobileUsers}
               handleImageErr={handleImageErr}
               handleViewFunc={handleViewFunc}
               handleEditFunc={handleEditFunc}
               handleDeleteOpen={handleDeleteOpen}
               

               />
        }
     </div>

  

</div>

            {/* <DeleteuserComponent
                show={modalShow}
                userStatus={userStatus}
                onHide={handleDeleteclose}
                onAccept={handleDeleteaccept}
                /> */}

            
       

        </>
    );
};

ListComponent.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, { userMenuDetails })(ListComponent);