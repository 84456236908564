import React, { useEffect, useState, useRef, useCallback } from 'react';
import "./userSubscription.scss";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { usersSubscriptionCountDetailsAction } from '../../redux/userSubscription/userSubscription.actions';
import axios from 'axios';
import { toast } from 'react-toastify';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { API_FREE_USERS_DETAILS } from '../../utils/consts/api';
import dummy from '../../assets/images/default-Profile-Pic.jpg';
import AgTable from '../../components/Table/AgTable';
import { FreeUsers } from '../../components/Table/tableData';
import SkeletonLoader from '../../components/Loader';




const FreeUsersComponent = ({ token }) => {
    const [spinner ,setSpinner]=useState(false);
    const dispatch = useDispatch();
    const query = useRef('');
    const pageLimit = useRef(5);
    const pageNo = useRef(0);
    const [pageNoMax] = useState(100000000);
    const [freeUserList, setFreeUserList] = useState([]);
    const [freeUsertListBackUp, setFreeUserListBackUp] = useState([]);
    const [cacheNextFreeUserList, setcacheNextFreeUserList] = useState([]);
    const [cachePrevFreeUserrList, setcachePrevFreeUserList] = useState([]);

    const resetCache = () => {
        pageNo.current = 0;
        setcacheNextFreeUserList([]);
        setcachePrevFreeUserList([]);
    }

    const handleImageErr = (e) => {
        e.target.src = dummy;
    };

    const entriesPerPage = useCallback((e) => {
        pageLimit.current = parseInt(e.target.value, 10);
        resetCache();
        getAllFreeUserList();
    }, []);


    const nextCacheSet = useCallback(() => {

        if (cacheNextFreeUserList.length > 0) {
            setcachePrevFreeUserList(freeUserList)

            setFreeUserList(cacheNextFreeUserList);
            setFreeUserListBackUp(cacheNextFreeUserList);

            setcacheNextFreeUserList([]);
            getNextPrevFreeUserList('next');
            dispatch(usersSubscriptionCountDetailsAction(cacheNextFreeUserList.length));
        } else {
            setcacheNextFreeUserList([]);
            getAllFreeUserList();
        }
    }, []);

    const prevCacheSet = useCallback(() => {
        if (cachePrevFreeUserrList.length > 0) {
            setcacheNextFreeUserList(freeUserList);

            setFreeUserList(cachePrevFreeUserrList);
            setFreeUserListBackUp(cachePrevFreeUserrList);

            setcachePrevFreeUserList([]);

            getNextPrevFreeUserList('prev');
            dispatch(usersSubscriptionCountDetailsAction(cachePrevFreeUserrList.length));
        } else {
            setcachePrevFreeUserList([]);
            getAllFreeUserList();
        }
    }, []);

    const nextPrev = useCallback((val) => {
        const page = pageNo.current + 1;
        if (val === 'next') {
            if (page < pageNoMax) {
                pageNo.current = pageNo.current + 1;
                nextCacheSet();
            }
        } else {
            if (page > 1) {
                pageNo.current = pageNo.current - 1;
                prevCacheSet();
            }
        }

    }, []);

    const searchItems = (e) => {

        if (e.target.value) {
            query.current = e.target.value;
            resetCache();
            getAllFreeUserList();
        } else if (e.target.value === '') {
            query.current = e.target.value;
            resetCache();
            getAllFreeUserList();
        }
    };

    const getNextPrevFreeUserList = useCallback(async (whichPage = 'next') => {
        whichPage = pageNo.current === 0 ? 'next' : whichPage;

        let nextPageNo = pageNo.current;
        if (whichPage === 'next') {
            nextPageNo = nextPageNo + 1;
        } else {
            nextPageNo = nextPageNo - 1;
        }
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_FREE_USERS_DETAILS, { page: nextPageNo, size: pageLimit.current, searchString: query.current }, { headers });
            if (res.data.responseCode === 0) {
                if (whichPage === 'next') {
                    const listdata = res.data.response.subscriptionUserList.length > 0 ? res.data.response.subscriptionUserList : [];
                    setcacheNextFreeUserList(listdata);
                } else {
                    const listdata = res.data.response.subscriptionUserList.length > 0 ? res.data.response.subscriptionUserList : [];
                    setcachePrevFreeUserList(listdata);
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, []);

    const getAllFreeUserList = async () => {


        setSpinner(true);

        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_FREE_USERS_DETAILS, 
                // { page: pageNo.current,  size: 2000 ||pageLimit.current,  searchString: query.current }, 
                {},
                { headers });
            if (res.data.responseCode === 0) {
                setFreeUserList(res.data.response.subscriptionUserList);
                setFreeUserListBackUp(res.data.response.subscriptionUserList);
                setSpinner(false);
                dispatch(usersSubscriptionCountDetailsAction(res.data.response.subscriptionUserList.length));
                // if (res.data.response.subscriptionUserList.length === pageLimit.current && cacheNextFreeUserList.length === 0) {
                //     getNextPrevFreeUserList();
                // }
            } else {

                setSpinner(false);
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {

            setSpinner(false);
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    useEffect(() => {
        getAllFreeUserList();
        dispatch(usersSubscriptionCountDetailsAction(freeUserList.length));
    }, []);

    return (
        <>
        <div style={{height:"88vh"}}>
            {/* <div className="row">
                <div className="col-12">
                    <div className='d-flex justify-content-between my-3 align-items-center'>
                        <div className="d-flex form-inputs" style={{ width: '40%' }}>
                            <input className={`form-control searchField`} type="text"
                                onChange={searchItems}
                                placeholder="Search free user"
                                value={query.current}
                            />
                            <i className="fa fa-search" style={{ marginLeft: '-30px', opacity: 0.5, marginTop: '12px', cursor: 'pointer' }} ></i>
                        </div>
                        <div className="w-100 customPginationDiv m-0">
                            <h6 className="customText">Items per page</h6>
                            <select className="form-select customSelectWidth" style={{ width: '8%' }} name='pageLimit' onChange={entriesPerPage}>
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                            </select>
                            <button className="btn customButtons buttonMargin" disabled={pageNo.current === 0} onClick={() => nextPrev('prev')}><i className="fas fa-angle-left"></i></button>

                            <button className="btn customButtons" disabled={pageLimit.current >= freeUserList.length && (cacheNextFreeUserList.length === 0 || freeUserList.length < 5)} onClick={() => nextPrev('next')}>
                                <i className="fas fa-angle-right"></i></button>
                        </div>
                    </div>
                </div>

                <div className='col-12'>
                    <div className="table-responsive">
                        <table className="table table-borderless custumTable">
                            <thead className="tablehead">
                                <tr>
                                    <th className="headText">Name</th>
                                    <th className="headText">Mobile Number</th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                                {freeUserList && freeUserList.length > 0 && freeUserList.map((ele, index) => (<tr key={index}>
                                    <td>
                                        <div className="d-flex w-100 align-items-center">
                                            <div>
                                                <img src={ele.userPhoto} onError={handleImageErr} alt="" width="38" height="38"
                                                    style={{ borderRadius: '50%' }} />
                                            </div>
                                            <div className="ms-2"> */}
                                                {/* <h6 className="m-0 bodyText">{ele.fullName}</h6> */}
                                                    {/* <h6 className="m-0 locationText">Bangalore</h6> */}
                                            {/* </div>
                                        </div>
                                    </td>
                                    <td className="bodyText">{ele.mobileNumber ? ele.mobileNumber : 'NA'}</td>
                                </tr>))}

                                {freeUserList && freeUserList.length === 0 &&
                                    <div className="text-center w-100">
                                        <h4>No Data Found</h4>
                                    </div>
                                }
                            </tbody>
                        </table>
                    </div>
                </div> */}


              <div style={{height:"90vh"}}>

              {spinner&&freeUserList.length==0?
                    <SkeletonLoader/> :
              <AgTable
                section={"FreeUsers"}
                rowData={freeUserList}
                colDefs={FreeUsers}
                />
              }
              </div>
            </div>
        </>
    );
};

FreeUsersComponent.propTypes = {
    token: PropTypes.string,
};

const mapStateToProps = (state) => ({
    token: state.user.token,
});

export default connect(mapStateToProps, null)(FreeUsersComponent);