import React from 'react';
import { Navigate } from 'react-router-dom';
import DashboardLayout from '../layouts/DashboardLayout';
import MainLayout from '../layouts/MainLayout';
import DashboardComponent from '../views/dashboard';
import SupportComponent from '../views/support';
import SuggestionComponent from '../views/suggestion';
import LoginComponent from '../views/auth/login';
import UsersComponent from '../views/users';
import VehicleTypeComponent from '../views/vehicleType';
import ExportDataComponent from '../views/exportData'
import ForgetPassowrdComponent from '../views/auth/forget-password';
import UserModuleComponent from '../views/users/userModule';
import UserDetailsComponent from '../views/users/userDetails';
import AdminProfileComponent from '../views/profile/index';
import AppContentManagementComponent from '../views/App-Content-Management/index';
import ServicePlanComponent from "../views/service-plans/ServicePlan";
import CRMUserDetailsComponent from "../views/crmUsers/userDetails";
import CRMUserModuleComponent from "../views/crmUsers/userModule";
import B2BPayments from "../views/B2B Payments/payment";
import B2CPayments from "../views/B2C Payments/payment";
import CRMUsersComponent from '../views/crmUsers';
import PaidUsersComponent from '../views/PaidUsers';
import AllBookingPage from '../views/all-booking-page/index';
import UsersSubscriptionsComponent from "../views/usersSubscriptions/index";
import FreeUsersComponent from "../views/usersSubscriptions/freeusers";
import PrimiumUsersComponent from "../views/usersSubscriptions/premiumUsers";
import B2CSupportComponent from "../views/B2C-Ticket-Support/index";
import { element } from 'prop-types';
import AutoSubscriptionUsers from '../views/usersSubscriptions/AutoSubscriptionUsers';
import B2bPaymentInfo from '../views/B2B Payments';
import FailedPayments from '../views/B2B Payments/failedPayments';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      // { path: '/', element: <DashboardComponent /> },
      { path: 'users', element: <UsersComponent /> },
      { path: 'vehicleType', element: <VehicleTypeComponent /> },
      { path: 'dashboard', element: <DashboardComponent /> },
      { path: 'support', element: <SupportComponent /> },
      { path: 'suggestions', element: <SuggestionComponent /> },
      { path: 'exportData', element: <ExportDataComponent /> },
      { path: 'usersEdit/:userId', element: <UserDetailsComponent /> },
      { path: 'users/:userId', element: <UserModuleComponent /> },
      { path: 'adminProfile', element: <AdminProfileComponent /> },
      { path: 'contentManagement', element: <AppContentManagementComponent /> },
      { path: 'servicePlans', element: <ServicePlanComponent /> },
      { path: 'b2cpayments', element: <B2CPayments /> },
      { path: 'b2bpayments', element: <B2bPaymentInfo /> ,
        children: [
          {path:'payment-details' , element : <B2BPayments/> },
          {path:'failed-payments' , element : <FailedPayments/> }
        ]
       },
      { path: 'b2c-support', element: <B2CSupportComponent/> },
      {
        path: 'usersSubscriptions',
        element: <UsersSubscriptionsComponent />,
        children: [
          { path: 'premium', element: <PrimiumUsersComponent /> },
          { path: 'free', element: <FreeUsersComponent /> },
          { path:"autoSubscription-users" , element:<AutoSubscriptionUsers/>}
        ]
      },
      { path: 'allBookings', element: <AllBookingPage /> },
      // crmusers
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <LoginComponent /> },
      { path: 'forget-password', element: <ForgetPassowrdComponent /> },
      // { path: '404', element: <NotFoundView /> },
      { path: '/', element: <Navigate to="/app/dashboard" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'crm',
    element: <DashboardLayout />,
    children: [
      { path: 'users', element: <CRMUsersComponent /> },
      { path: 'user/:userId', element: <CRMUserModuleComponent /> },
      { path: 'userEdit/:userId', element: <CRMUserDetailsComponent /> },
    ]
  }
];

export default routes;
