import { useState } from "react";
import { GrMenu } from "react-icons/gr";
import styles from '../dashboardlayout.module.scss';
import {
  Menu,
  MenuItem,
  ProSidebar,
} from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import PropTypes from 'prop-types';
import '../dashboardlayout.module.scss'
import { NavLink } from "react-router-dom";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

const NavItem = ({ items, openMobile, onMobileClose }) => {


  const url=window.location.pathname.split('/').slice(0, 3).join('/');
  

  const close = () => {
    onMobileClose(!openMobile)
  }

  return (
    <div className={styles.header} >
      <ProSidebar className="proSidebar" collapsed={!openMobile}>
        <Menu iconShape="square">
          <MenuItem
            icon={<GrMenu onClick={close} />}>
            Main Menu
          </MenuItem>
          {
            items.map(item => (
              <>
                {!openMobile && <OverlayTrigger
                  key={item.title}
                  placement='right'
                  overlay={<Tooltip id={`tooltip-top`}>
                    {item.title}
                  </Tooltip>}
                >
                  <MenuItem icon={item.icon} className={url==item.href?"activeIcon":""}>
                    <NavLink to={item.href} >
                      {item.title}
                    </NavLink>
                  </MenuItem>
                </OverlayTrigger>}

                {openMobile && <MenuItem icon={item.icon} className={url==item.href?"activeIcon":""}>
                    <NavLink to={item.href} >
                      {item.title}
                    </NavLink>
                  </MenuItem>}

              </>
            ))
          }
        </Menu>
      </ProSidebar>
    </div>
  );
};

NavItem.propTypes = {
  className: PropTypes.string,
  href: PropTypes.string,
  icon: PropTypes.elementType,
  title: PropTypes.string
};

export default NavItem;
