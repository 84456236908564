import React, { useEffect, useState, useRef, useCallback } from 'react';
import "./userSubscription.scss";
import PropTypes from "prop-types";
import { connect, useDispatch } from "react-redux";
import { usersSubscriptionCountDetailsAction } from '../../redux/userSubscription/userSubscription.actions';
import { API_FETCH_DROPDOWN_FILTER, API_PREMIUM_USERS_DETAILS } from '../../utils/consts/api';
import { toast } from 'react-toastify';
import axios from 'axios';
import dummy from '../../assets/images/default-Profile-Pic.jpg';
import SkeletonLoader from '../../components/Loader';
import AgTable from '../../components/Table/AgTable';
import { PremiumUsers } from '../../components/Table/tableData';

const PrimiumUsersComponent = ({token}) => {
    const dispatch = useDispatch();
    const query = useRef('');
    const pageLimit = useRef(5);
    const pageNo = useRef(0);

    const [spinner,setSpinner]=useState(false);
    const [pageNoMax] = useState(100000000);
    const [premiumUserList, setPremiumUserList] = useState([]);
    const [premiumUsertListBackUp, setPremiumUserListBackUp] = useState([]);
    const [cacheNextPremiumUserList, setcacheNextPremiumUserList] = useState([]);
    const [cachePrevPremiumUserrList, setcachePrevPremiumUserList] = useState([]);
    const [premiumDropDownMenu, setPremiumDropDownMenu] =useState([]);
    const dropDownOption=useRef('')
    const resetCache = () => {
        pageNo.current = 0;
        setcacheNextPremiumUserList([]);
        setcachePrevPremiumUserList([]);
    }

    const entriesPerPage = useCallback((e) => {
        pageLimit.current = parseInt(e.target.value, 10);
        resetCache();
        getAllPremiumUserList();
    }, []);

    const entriesDropdown = useCallback((e) => {
            dropDownOption.current = e.target.value;
        resetCache();
        getAllPremiumUserList()
    }, []);

    const handleImageErr = (e) => {
        e.target.src = dummy;
    };

    const nextCacheSet = useCallback(() => {

        if (cacheNextPremiumUserList.length > 0) {
            setcachePrevPremiumUserList(premiumUserList)

            setPremiumUserList(cacheNextPremiumUserList);
            setPremiumUserListBackUp(cacheNextPremiumUserList);

            setcacheNextPremiumUserList([]);
            getNextPrevPremiumUserList('next');
            dispatch(usersSubscriptionCountDetailsAction(cacheNextPremiumUserList.length));
        } else {
            setcacheNextPremiumUserList([]);
            getAllPremiumUserList();
        }
    }, []);

    const prevCacheSet = useCallback(() => {
        if (cachePrevPremiumUserrList.length > 0) {
            setcacheNextPremiumUserList(premiumUserList);

            setPremiumUserList(cachePrevPremiumUserrList);
            setPremiumUserListBackUp(cachePrevPremiumUserrList);

            setcachePrevPremiumUserList([]);

            getNextPrevPremiumUserList('prev');
            dispatch(usersSubscriptionCountDetailsAction(cachePrevPremiumUserrList.length));
        } else {
            setcachePrevPremiumUserList([]);
            getAllPremiumUserList();
        }
    }, []);

    const nextPrev = useCallback((val) => {
        const page = pageNo.current + 1;
        if (val === 'next') {
            if (page < pageNoMax) {
                pageNo.current = pageNo.current + 1;
                nextCacheSet();
            }
        } else {
            if (page > 1) {
                pageNo.current = pageNo.current - 1;
                prevCacheSet();
            }
        }

    }, []);

    const searchItems = (e) => {

        if (e.target.value) {
            query.current = e.target.value;
            resetCache();
            getAllPremiumUserList();
        } else if (e.target.value === '') {
            query.current = e.target.value;
            resetCache();
            getAllPremiumUserList();
        }
    };

    const fetchDropDownMenu= async ()=>{
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.get(API_FETCH_DROPDOWN_FILTER, { headers });
            if (res.data.responseCode === 0) {
                setPremiumDropDownMenu(res.data.response.subscriptionList);
                setPremiumUserListBackUp(res.data.response.subscriptionUserList)
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }

    const getNextPrevPremiumUserList = useCallback(async (whichPage = 'next') => {
        whichPage = pageNo.current === 0 ? 'next' : whichPage;

        let nextPageNo = pageNo.current;
        if (whichPage === 'next') {
            nextPageNo = nextPageNo + 1;
        } else {
            nextPageNo = nextPageNo - 1;
        }
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_PREMIUM_USERS_DETAILS, { page: nextPageNo, size: pageLimit.current, searchString: query.current,subscriptionId:dropDownOption.current }, { headers });
            if (res.data.responseCode === 0) {
                if (whichPage === 'next') {
                    const listdata = res.data.response.subscriptionUserList.length > 0 ? res.data.response.subscriptionUserList : [];
                    setcacheNextPremiumUserList(listdata);
                } else {
                    const listdata = res.data.response.subscriptionUserList.length > 0 ? res.data.response.subscriptionUserList : [];
                    setcachePrevPremiumUserList(listdata);
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, []);

    const getAllPremiumUserList = async () => {

        setSpinner(true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_PREMIUM_USERS_DETAILS,
                //  { page: pageNo.current, size: 2000||pageLimit.current, searchString: query.current,subscriptionId:dropDownOption.current },
                    {},
                  { headers });
            if (res.data.responseCode === 0) {
                setPremiumUserList(res.data.response.subscriptionUserList);
                setPremiumUserListBackUp(res.data.response.subscriptionUserList);
                setSpinner(false);
                dispatch(usersSubscriptionCountDetailsAction(res.data.response.subscriptionUserList.length));
                // if (res.data.response.subscriptionUserList.length === pageLimit.current && cacheNextPremiumUserList.length === 0) {
                //     getNextPrevPremiumUserList();
                // }
            } else {

                setSpinner(false);
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {

            setSpinner(false);
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };


    useEffect(() => {
        dispatch(usersSubscriptionCountDetailsAction(0));
        // fetchDropDownMenu(); dropdown of the premium plans
        getAllPremiumUserList();
      }, []);

    return (
        <>
            <div className="row">
                {/* <div className="col-12">
                    <div className='d-flex justify-content-between my-3 align-items-center'>
                        <div className="d-flex form-inputs" style={{ width: '40%' }}>
                            <input className={`form-control searchField`} type="text"
                                onChange={searchItems}
                                placeholder="Search premium user"
                                value={query.current}
                            />
                            <i className="fa fa-search" style={{ marginLeft: '-30px', opacity: 0.5, marginTop: '12px', cursor: 'pointer' }} ></i>
                        </div>
                        <div className='w-100 customPginationDiv m-0'>
                            <h6 className="customText">Premium Filters</h6>
                            <select className="form-select customSelectWidth m-0" style={{ width: '15%' }} name='premiumFilters' onChange={entriesDropdown}>
                                <option value="">All</option>
                                { premiumDropDownMenu && premiumDropDownMenu.length > 0 && premiumDropDownMenu.map((ele, index) => (
                                    <option value={ele.subscriptionId} key={index}>{ele.planTitle}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div> */}
                {/* <div className='col-12'>
                    <div className="table-responsive">
                        <table className="table table-borderless custumTable">
                            <thead className="tablehead">
                                <tr>
                                    <th className="headText">User</th>
                                    <th className="headText">Mobile Number</th>
                                    <th className="headText">expiry Date</th>
                                    <th className="headText">Subscription Type</th>
                                </tr>
                            </thead>
                            <tbody className="tableBody">
                                {premiumUserList && premiumUserList.length > 0 && premiumUserList.map((ele, index) => (<tr key={index}>
                                    <td>
                                        <div className="d-flex w-100 align-items-center">
                                            <div>
                                                <img src={ele.image} onError={handleImageErr} alt="" width="38" height="38"
                                                    style={{ borderRadius: '50%' }} />
                                            </div>
                                            <div className="ms-2">
                                                <h6 className="m-0 bodyText">{ele.fullName}</h6>
                                                    
                                            </div>
                                        </div>
                                    </td>
                                    <td className="bodyText">{ele.mobileNumber ? ele.mobileNumber : 'NA'}</td>
                                    <td className="bodyText">{ele.secureDate ? ele.secureDate : 'NA'}</td>
                                    <td className="bodyText">{ele.planTitle ? ele.planTitle : 'NA'}</td>
                                </tr>))}

                                {premiumUserList && premiumUserList.length === 0 &&
                                    <div className="text-center w-100">
                                        <h4>No Data Found</h4>
                                    </div>
                                }
                            </tbody>
                        </table>
                    </div>
                </div> */}
                {/* <div className="col-12">
                    <div className="w-100 customPginationDiv mb-0 mt-3">
                        <h6 className="customText">Items per page</h6>
                        <select className="form-select customSelectWidth" style={{ width: '8%' }} name='pageLimit' onChange={entriesPerPage}>
                            <option value="5">5</option>
                            <option value="10">10</option>
                            <option value="20">20</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                        </select>
                        <button className="btn customButtons buttonMargin" disabled={pageNo.current === 0} onClick={() => nextPrev('prev')}><i className="fas fa-angle-left"></i></button>

                        <button className="btn customButtons" disabled={pageLimit.current >= premiumUserList.length && (cacheNextPremiumUserList.length === 0 || premiumUserList.length < 5)} onClick={() => nextPrev('next')}>
                            <i className="fas fa-angle-right"></i></button>
                    </div>
                </div> */}
                
                 <div style={{height:"90vh"}}>

              {spinner&&premiumUserList.length==0?
                    <SkeletonLoader/> :
              <AgTable
                section={"PremiumUsers"}
                rowData={premiumUserList}
                colDefs={PremiumUsers}
                />
              }
              </div>
            </div>
        </>
    );
};

PrimiumUsersComponent.propTypes = {
    token: PropTypes.string,
};

const mapStateToProps = (state) => ({
    token: state.user.token,
});

export default connect(mapStateToProps, null)(PrimiumUsersComponent);