/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import './bookingListPage.scss';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { API_ALL_BOOKING_DETAILS, API_EXPORT_BOOKING_DETAILS } from '../../utils/consts/api';
import * as moment from 'moment';
import { BiPhone } from "react-icons/bi";
import { GrSecure } from "react-icons/gr";
import { MdOutlineInfo } from "react-icons/md";
import AgTable from '../../components/Table/AgTable';
import { BookingsFields } from '../../components/Table/tableData';
import SkeletonLoader from '../../components/Loader';

const BookingListComp = ({ token }) => {
    const query = useRef('');
    const [showLoader, setShowLoader] = useState(false);
    const [spinner,setSpinner]=useState(false);
    const pageLimit = useRef(5);
    const pageNo = useRef(0);
    const [pageNoMax] = useState(100000000);
    const [bookingList, setBookingList] = useState([]);
    const [bookingtListBackUp, setBookingListBackUp] = useState([]);
    const [cacheNextBookingList, setcacheNextBookingList] = useState([]);
    const [cachePrevBookingrList, setcachePrevBookingList] = useState([]);

    const resetCache = () => {
        pageNo.current = 0;
        setcacheNextBookingList([]);
        setcachePrevBookingList([]);
    }

    const entriesPerPage = useCallback((e) => {
        pageLimit.current = parseInt(e.target.value, 10);
        resetCache();
        getAllBookingList();
    }, []);


    const nextCacheSet = useCallback(() => {

        if (cacheNextBookingList.length > 0) {
            setcachePrevBookingList(bookingList)

            setBookingList(cacheNextBookingList);
            setBookingListBackUp(cacheNextBookingList);

            setcacheNextBookingList([]);
            getNextPrevBookingList('next');
        } else {
            setcacheNextBookingList([]);
            getAllBookingList();
        }
    }, []);

    const prevCacheSet = useCallback(() => {
        if (cachePrevBookingrList.length > 0) {
            setcacheNextBookingList(bookingList);

            setBookingList(cachePrevBookingrList);
            setBookingListBackUp(cachePrevBookingrList);

            setcachePrevBookingList([]);

            getNextPrevBookingList('prev');
        } else {
            setcachePrevBookingList([]);
            getAllBookingList();
        }
    }, []);

    const nextPrev = useCallback((val) => {
        const page = pageNo.current + 1;
        if (val === 'next') {
            if (page < pageNoMax) {
                pageNo.current = pageNo.current + 1;
                nextCacheSet();
            }
        } else {
            if (page > 1) {
                pageNo.current = pageNo.current - 1;
                prevCacheSet();
            }
        }

    }, []);

    const getNextPrevBookingList = useCallback(async (whichPage = 'next') => {
        whichPage = pageNo.current === 0 ? 'next' : whichPage;

        let nextPageNo = pageNo.current;
        if (whichPage === 'next') {
            nextPageNo = nextPageNo + 1;
        } else {
            nextPageNo = nextPageNo - 1;
        }
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_ALL_BOOKING_DETAILS, { page: nextPageNo, size: pageLimit.current, searchId: query.current }, { headers });
            if (res.data.responseCode === 0) {
                if (whichPage === 'next') {
                    const listdata = res.data.response.bookingList.length > 0 ? res.data.response.bookingList : [];
                    setcacheNextBookingList(listdata);
                } else {
                    const listdata = res.data.response.bookingList.length > 0 ? res.data.response.bookingList : [];
                    setcachePrevBookingList(listdata);
                }
            } else {
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }
    }, []);

    const getAllBookingList = async () => {
        setSpinner(true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.post(API_ALL_BOOKING_DETAILS,
                //  { page: pageNo.current, size: 2000,
                //      searchId: query.current },
                {} ,
                      { headers });
            if (res.data.responseCode === 0) {
                setBookingList(res.data.response.bookingList);
                setBookingListBackUp(res.data.response.bookingList);
                setSpinner(false);
                if (res.data.response.bookingList.length === pageLimit.current && cacheNextBookingList.length === 0) {
                    getNextPrevBookingList();
                }
            } else {
                setSpinner(false);
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            setSpinner(false);
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }

    };

    useEffect(() => {
        getAllBookingList();
    }, []);

    const searchItems = (e) => {

        if (e.target.value) {
            query.current = e.target.value;
            resetCache();
            getAllBookingList();
        } else if (e.target.value === '') {
            query.current = e.target.value;
            resetCache();
            getAllBookingList();
        }
    };

    const validateNum = (event) => {
        if (!((event.key >= '0' && event.key <= '9') || event.key === 'Delete' || event.key === 'Backspace' || event.key === 'ArrowLeft' || event.key === 'ArrowRight')) {
            event.preventDefault();
        }
    }

    const saveAsExcel = async () => {
        setShowLoader(true);
        try {
            const headers = {
                'Content-Type': 'application/json',
                authorization: token
            };
            const res = await axios.get(API_EXPORT_BOOKING_DETAILS, { headers });
            if (res.data.responseCode === 0 && res.data.response.downloadUrl) {
                window.open(res.data.response.downloadUrl, '_self');
                setShowLoader(false);
            } else {
                setShowLoader(false);
                toast.error(res.data.errorMsg, {
                    position: "top-right",
                    autoClose: 4000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        } catch (err) {
            setShowLoader(false);
            toast.error('Internal server error.', {
                position: "top-right",
                autoClose: 4000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        }


    }

    return (
        <>
            <div className="" style={{height:"90vh"}}>
                {/* <div className="col-12 p-0"> */}
                    
                    {/* <div className='d-flex'> */}
                        {/* <h5 className="customTitleName">All Bookings</h5>
                        <p className="ms-3 bookingSearchText">{bookingList.length > 0 ? bookingList.length : 0} Bookings</p> */}
                        {/* <div className="col-md-8"> */}
                            {/* <div className="d-flex form-inputs">
                                <input className={`form-control searchBar`} type="text"
                                    onChange={searchItems}
                                    placeholder="Search by id"
                                    onKeyDown={validateNum}
                                    value={query.current}
                                />
                                <i className="fa fa-search" style={{ marginLeft: '-30px', opacity: 0.5, marginTop: '22px', cursor: 'pointer' }} ></i>
                            </div> */}
                        {/* </div> */}
                    {/* </div> */}

                    {/* <div className="card customCard"> */}
                        {/* <div className="card-body">
                            <div className="row">
                                <div className="col-12">
                                    <div className='d-flex justify-content-between'>
                                        <div>
                                            <button className="btn customButtonActive customDisabledBtn" onClick={saveAsExcel} disabled={showLoader}>Export Data
                                                {showLoader ? <div className={`buttonLoader`}></div> : null}
                                            </button>
                                        </div>
                                        <div className="w-100 customPginationDiv">
                                            <h6 className="customText">Items per page</h6>
                                            <select className="form-select customSelectWidth" style={{ width: '8%' }} name='pageLimit' onChange={entriesPerPage}>
                                                <option value="5">5</option>
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                            <button className="btn customButtons buttonMargin" disabled={pageNo.current === 0} onClick={() => nextPrev('prev')}><i className="fas fa-angle-left"></i></button>

                                            <button className="btn customButtons" disabled={pageLimit.current >= bookingList.length && (cacheNextBookingList.length === 0 || bookingList.length < 5)} onClick={() => nextPrev('next')}>
                                                <i className="fas fa-angle-right"></i></button>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-12">
                                    <div className="table-responsive">
                                        <table className="table table-borderless custumTable">
                                            <thead className="tablehead">
                                                <tr>
                                                    <th className="headText">Id</th>
                                                    <th className="headText">Agent</th>
                                                    <th className="headText">Pickup</th>
                                                    <th className="headText">Drop</th>
                                                    <th className="headText">Vehicle Type</th>
                                                    <th className="headText">Pickup Date</th>
                                                    <th className="headText">Trip</th>
                                                    <th className="headText">Amount</th>
                                                    <th className="headText">Status</th>
                                                    <th className="headText">Driver</th>
                                                </tr>
                                            </thead>
                                            <tbody className="tableBody">
                                                {bookingList && bookingList.length > 0 && bookingList.map((ele) => (<tr key={ele.bookingId} style={ele.bookingStatus.toLowerCase() === 'deleted' ? { backgroundColor: '#d3d3d33b' } : null}>
                                                    <td className="">
                                                        <OverlayTrigger
                                                            key={'top'}
                                                            placement='top'
                                                            overlay={
                                                                <Tooltip id={`tooltip-top`}>
                                                                    {ele.isSecure === 1 ? 'Secured' : 'Not Secured'}
                                                                </Tooltip>
                                                            }
                                                        >
                                                            <div className="d-flex w-100 align-items-center">

                                                                <GrSecure className={ele.isSecure === 1 ? 'isSecure' : 'isnotSecure'} />

                                                                <span className='ms-1 bodyText'>{ele.bookingId}</span>
                                                            </div>
                                                        </OverlayTrigger>
                                                    </td>
                                                    <td className="bodyText">
                                                        {ele.postedAgentName ? ele.postedAgentName : 'NA'}
                                                        <div>
                                                            {
                                                             ele?.customerId ?                                                                
                                                             <OverlayTrigger
                                                                key={'right'}
                                                                delay={{ show: 250, hide: 400 }}
                                                                placement='right'
                                                                className="testing"
                                                                overlay={
                                                                    <Tooltip id={`tooltip-left`}>
                                                                        <div >
                                                                            <div className="infoOverlayDiv">
                                                                                <div >Agent : </div>
                                                                                <div > {ele.postedMobileNo ? <span><BiPhone /> +919258196242</span> : '+919258196242'}</div>
                                                                            </div>
                                                                            <div className="infoOverlayDiv">
                                                                                <div >Customer : </div>
                                                                                <div >{ele.customerMobileNumber ? <span><BiPhone /> {ele.customerMobileNumber}</span> : '-'}</div>
                                                                            </div>
                                                                        </div>
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <span>Info <MdOutlineInfo /></span>
                                                             </OverlayTrigger>
                                                             :
                                                             <h6 className="m-0 mt-1 bodyText" style={{ fontSize: '11.2px' }}>
                                                               <BiPhone /> {ele.postedMobileNo ? ele.postedMobileNo : '-'}
                                                             </h6>
                                                            }
                                                        </div>
                                                    </td>
                                                    <td className="bodyText">{ele.pickupCity ? ele.pickupCity : 'NA'}</td>
                                                    <td className="bodyText">{ele.dropCity ? ele.dropCity : 'NA'}</td>
                                                    <td className="bodyText">{ele.vehicleTypeName ? ele.vehicleTypeName : 'NA'}</td>
                                                    <td className="bodyText">{ele.pickupDate ? moment(new Date(ele.pickupDate)).format('MMMM D, Y') : 'NA'}</td>
                                                    <td className="bodyText">{ele.bookingType ? ele.bookingType : 'NA'}</td>
                                                    <td className="bodyText">₹ {ele.bookingAmount ? ele.bookingAmount : 'NA'}</td>
                                                    <td className="bodyText" >
                                                        {ele.bookingStatus ?
                                                            <>
                                                                {ele.bookingStatus.toLowerCase() === 'new' && <div className="statusDiv new">{ele.bookingStatus}</div>}
                                                                {ele.bookingStatus.toLowerCase() === 'expired' && <div className="statusDiv expired">{ele.bookingStatus}</div>}
                                                                {ele.bookingStatus.toLowerCase() === 'assigned' && <div className="statusDiv assigned">{ele.bookingStatus}</div>}
                                                                {ele.bookingStatus.toLowerCase() === 'cancel' && <div className="statusDiv cancel">{ele.bookingStatus}</div>}
                                                                {ele.bookingStatus.toLowerCase() === 'picked' && <div className="statusDiv picked">{ele.bookingStatus}</div>}
                                                                {ele.bookingStatus.toLowerCase() === 'end' && <div className="statusDiv end">{ele.bookingStatus}</div>}
                                                                {ele.bookingStatus.toLowerCase() === 'deleted' && <div className="statusDiv deleted">{ele.bookingStatus}</div>}
                                                            </>
                                                            :
                                                            'NA'
                                                        }
                                                    </td>
                                                    <td className="bodyText">
                                                        {ele.driverAgentName ? ele.driverAgentName : 'NA'}
                                                        <h6 className="m-0 mt-1" style={{ fontSize: '11.2px' }}>
                                                            <BiPhone /> {ele.driverMobileNo ? '+91 ' + ele.driverMobileNo : 'NA'}
                                                        </h6>
                                                    </td>
                                                </tr>))}

                                                {bookingList && bookingList.length === 0 &&
                                                    <div className="text-center w-100">
                                                        <h4>No Data Found</h4>
                                                    </div>
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                            </div>
                        </div> */}

        {/* </div> */}
  
            
        {/* </div> */}
        <div className='bookingList' >
       
         <div  className='header'>
         <h1 className='customTitleName'>All Bookings</h1>
        <Button className="exportButton" onClick={saveAsExcel} disabled={showLoader}>
            {showLoader ? ("Downloading...") : (
            <>
            Export Data
            <svg 
            xmlns="http://www.w3.org/2000/svg" 
            width="28" 
            height="18" 
            fill="currentColor" 
            className="bi bi-cloud-arrow-down-fill" 
            viewBox="0 0 16 16"
            >
            <path d="M8 2a5.53 5.53 0 0 0-3.594 1.342c-.766.66-1.321 1.52-1.464 2.383C1.266 6.095 0 7.555 0 9.318 0 11.366 1.708 13 3.781 13h8.906C14.502 13 16 11.57 16 9.773c0-1.636-1.242-2.969-2.834-3.194C12.923 3.999 10.69 2 8 2m2.354 6.854-2 2a.5.5 0 0 1-.708 0l-2-2a.5.5 0 1 1 .708-.708L7.5 9.293V5.5a.5.5 0 0 1 1 0v3.793l1.146-1.147a.5.5 0 0 1 .708.708"/>
            </svg>
            </>
            )}
        </Button>

        </div>
        {
        spinner&&bookingList.length==0?
        <SkeletonLoader/> :   
        <AgTable 
         rowData={bookingList}
         colDefs={BookingsFields}
         section={'AllBookingRows'}
        />}
        </div>

        </div>
        </>
    );
};

BookingListComp.propTypes = {
    token: PropTypes.string
};

const mapStateToProps = (state, props) => ({
    token: state.user.token
});

export default connect(mapStateToProps, null)(BookingListComp);